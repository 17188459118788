import React, { useContext } from 'react';
import { Box, ClickAwayListener, Link, ListItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeaderContext, NavOption } from '../../context';
import clsx from 'clsx';
import { HeaderSubNav } from '../HeaderSubNav/HeaderSubNav';
import styles from './styles.module.scss';

export const HeaderNavItem: React.FC<{ option: NavOption }> = ({ option }) => {
  const { setOpenedSubMenu, openedSubMenu, setMenuIsOpen } = useContext(HeaderContext);

  console.log('openedSubMenu', openedSubMenu);

  const handleClick = (e: React.MouseEvent, option: NavOption): void => {
    if (!option.link) {
      e.preventDefault();
      setOpenedSubMenu(openedSubMenu && openedSubMenu === option.id ? null : option.id);
    } else {
      setOpenedSubMenu(null);
      setMenuIsOpen(false);
    }
  };

  const handleClickAway = (): void => {
    if (option.id === openedSubMenu) {
      setOpenedSubMenu(null);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ListItem
        className={clsx(styles.navListItem, {
          [styles.opened]: openedSubMenu === option.id,
        })}
      >
        <Link
          href={option.link || '#'}
          onClick={(e): void => {
            handleClick(e, option);
          }}
          className={clsx(styles.navListItemLink, { [styles.noArrow]: !!option.link })}
        >
          {option.title}
          {!option.link && (
            <Box component="span" className={styles.navListItemLinkIcon}>
              <KeyboardArrowDownIcon />
            </Box>
          )}
        </Link>
        {!option.link && option?.children && <HeaderSubNav items={option.children} />}
      </ListItem>
    </ClickAwayListener>
  );
};
