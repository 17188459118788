import { FC } from 'react';
import clsx from 'clsx';
import { Container } from '@/components';
import styles from './styles.module.scss';
import Link from 'next/link';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

export interface BreadcrumbItem {
  label: string;
  url: string;
}

interface BreadcrumbsProps {
  color?: 'blue' | 'dark';
  items?: BreadcrumbItem[];
  classes?: {
    container?: string;
    list?: string;
    listItem?: string;
    active?: string;
    divider?: string;
    link?: string;
  };
  float?: boolean;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  classes = {},
  color = 'dark',
  float,
}) => {
  const { t } = useTranslation('common');
  const _items = [{ label: t('breadcrumbs.home'), url: '/' }];

  if (items?.length) {
    _items.push(...items);
  }

  return (
    <Container
      className={clsx(styles.container, classes.container, { [styles.float]: float })}
    >
      <nav aria-label="breadcrumb">
        <ul className={clsx(styles.breadcrumbsList, classes.list, styles[color])}>
          {_items.map((item, index) => (
            <li
              className={clsx(
                styles.item,
                {
                  [styles.itemActive]: index === _items.length - 1,
                },
                classes.listItem,
              )}
              key={index}
            >
              {index === _items.length - 1 ? (
                <>{item.label}</>
              ) : (
                <Box className={styles.inactiveItemContainer}>
                  <Link className={clsx(styles.link, classes.link)} href={item.url}>
                    {item.label}
                  </Link>
                  <span className={clsx(styles.divider, classes.divider)}>{'>'}</span>
                </Box>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </Container>
  );
};
